import { maxBy, minBy, startCase, sumBy } from 'lodash-es'

import type { Coverage, VetFeesCoverage } from '@/@types/Coverage'
import type { CoveragePrice } from '@/@types/Price'
import { MandatoryCopayInterval } from '@/domain/Shop/ProductCatalog'
import { AvailableProductPrice } from '@/domain/Shop/ProductPrice'

import { extractCoPayment } from '.'

export const calculatePriceFromCoverages = (
  coverages: Record<string, Coverage>
): CoveragePrice => {
  return {
    annual: sumBy(
      Object.values(coverages),
      (coverage) => coverage.price?.annual?.amount ?? 0
    ),
    monthly: sumBy(
      Object.values(coverages),
      (coverage) => coverage.price?.month?.amount ?? 0
    ),
  }
}

export interface CoverageBundle {
  sku?: string
  mandatoryCopayIntervals?: MandatoryCopayInterval[]
  price?: AvailableProductPrice
  compulsory: Coverage[]
  optional: Coverage[]
  product?: string
  policyId?: string
  accidentWaitingPeriodDays?: number
}

export type BundleCoverages = Record<string, CoverageBundle[]>
export type BundleProducts = Record<string, BundleCoverages>

export const getMinimumPriceForBundle = (
  bundle: CoverageBundle,
  addOns: Coverage[]
): CoveragePrice => {
  const totalAnnualAddonsPrices =
    sumBy(
      addOns,
      (coverage) => coverage.productPrice?.annualTotal?.total ?? 0
    ) ?? 0
  const totalMonthlyAddonsPrices =
    sumBy(
      addOns,
      (coverage) => coverage.productPrice?.monthlyTotal?.total ?? 0
    ) ?? 0

  return {
    annual: totalAnnualAddonsPrices + (bundle.price?.annualTotal?.total ?? 0),
    monthly:
      totalMonthlyAddonsPrices + (bundle.price?.monthlyTotal?.total ?? 0),
  }
}

export const getMaximumPriceForBundle = (
  bundle: CoverageBundle
): CoveragePrice => {
  const { annual: minAnual, monthly: minMonthly } = getMinimumPriceForBundle(
    bundle,
    []
  )

  const totalAnnualAddonsPrices = sumBy(
    bundle.optional,
    (coverage) => coverage.productPrice?.annualTotal?.total ?? 0
  )
  const totalMonthlyAddonsPrices = sumBy(
    bundle.optional,
    (coverage) => coverage.productPrice?.monthlyTotal?.total ?? 0
  )

  return {
    annual: minAnual + totalAnnualAddonsPrices,
    monthly: minMonthly + totalMonthlyAddonsPrices,
  }
}

export const getBundlePricesRangeForOrder = (
  bundles: CoverageBundle[],
  addOns: Coverage[]
): { min?: CoveragePrice; max?: CoveragePrice } => {
  return {
    min: minBy(
      bundles.map((bundle) => getMinimumPriceForBundle(bundle, addOns)),
      'annual'
    ),
    max: maxBy(bundles.map(getMaximumPriceForBundle), 'annual'),
  }
}

export const getBundlePricesRange = (
  bundles: CoverageBundle[],
  addOns: Coverage[]
): { min?: CoveragePrice; max?: CoveragePrice } => {
  return {
    min: minBy(
      bundles.map((bundle) => getMinimumPriceForBundle(bundle, addOns)),
      'annual'
    ),
    max: maxBy(bundles.map(getMaximumPriceForBundle), 'annual'),
  }
}

export const getBundleWaitingPeriod = (
  bundles: CoverageBundle[]
): Maybe<number> => {
  return bundles.find(
    (bundle) => bundle.accidentWaitingPeriodDays !== undefined
  )?.accidentWaitingPeriodDays
}

export const getCheapestBundleOrder = (
  bundles: CoverageBundle[],
  addOns: Coverage[]
): Maybe<CoverageBundle> =>
  minBy(bundles, (bundle) => getMinimumPriceForBundle(bundle, addOns).annual)

export const getCheapestBundle = (
  bundles: CoverageBundle[],
  addOns: Coverage[]
): Maybe<CoverageBundle> =>
  minBy(bundles, (bundle) => getMinimumPriceForBundle(bundle, addOns).annual)

export interface BundleOptions {
  vetFees: Maybe<number>
  excess: Maybe<number>
  copay: Maybe<number>
}

export const getBundleOptions = (
  bundle: CoverageBundle
): Nullable<BundleOptions> => {
  const coverage = bundle.compulsory.find(isVetFeesCoverage)

  if (!coverage) {
    return null
  }

  return {
    vetFees: coverage.limits.annual.limit,
    excess: coverage.excess,
    copay: extractCoPayment(coverage),
  }
}

export const getBundleAddons = (bundle: CoverageBundle): string[] => {
  return bundle.optional
    .filter((addon) => addon.coverage !== undefined)
    .map((addon) => addon.coverage) as string[]
}

export const getFilteredBundles = (
  bundles: CoverageBundle[],
  filterOptions: Nullable<BundleOptions>,
  selectedAddons?: Coverage[]
): CoverageBundle[] => {
  let result = bundles

  if (filterOptions) {
    const { vetFees, copay, excess } = filterOptions

    result = result.filter((bundle: CoverageBundle) => {
      const bundleWithVetFeeCoverage = bundle.compulsory.find(isVetFeesCoverage)

      if (!bundleWithVetFeeCoverage) {
        return false
      }

      if (bundleWithVetFeeCoverage.limits?.annual.limit !== vetFees) {
        return false
      }

      if (extractCoPayment(bundleWithVetFeeCoverage) !== copay) {
        return false
      }

      if (bundleWithVetFeeCoverage.excess !== excess) {
        return false
      }

      return true
    })
  }

  if (selectedAddons && selectedAddons.length) {
    return result.filter((bundle) =>
      selectedAddons.every((coverage) =>
        bundle.optional.some((optional) => optional.key === coverage.key)
      )
    )
  }

  return result
}

export const getAllCoveragesOptions = (bundles: CoverageBundle[]): string[] => {
  const coverageOptionSet: Set<string> = new Set()

  bundles.forEach((bundle) => {
    bundle.compulsory.forEach((coverage: Coverage) => {
      if (isVetFeesCoverage(coverage)) {
        Object.keys(coverage.limits as any).forEach((key) => {
          coverageOptionSet.add(key)
        })

        coverageOptionSet.add('co-payment') // not sure if this is needed, this is "our domain"
        coverageOptionSet.add('excess')
        coverageOptionSet.add('limit')
      }

      if (coverage.coverage) {
        coverageOptionSet.add(coverage.coverage)
      } else if (coverage.key) {
        coverageOptionSet.add(coverage.key)
      }
    })
  })
  return Array.from(coverageOptionSet)
}

export const findCoverageByOption = (
  bundle: CoverageBundle,
  option: string
): Maybe<Coverage> => {
  let found: Maybe<Coverage> = undefined

  bundle.compulsory.forEach((coverage: Coverage) => {
    if (isVetFeesCoverage(coverage)) {
      Object.keys(coverage.limits as any).forEach((key) => {
        if (key === option) {
          found = (coverage.limits as any)[key]
        }
      })
    } else if (coverage.coverage === option || coverage.key === option) {
      found = coverage
    }
  })

  return found
}

export const getCoverageLimit = (
  coverage: Coverage
): Maybe<Nullable<number | string>> => {
  if (coverage.limit === undefined && coverage.limits === undefined) {
    return undefined
  }

  if (coverage.limit || coverage.limit === null) {
    return coverage.limit
  }

  if (
    coverage.limits?.annual?.limit === null ||
    typeof coverage.limits?.annual?.limit === 'number' ||
    typeof coverage.limits?.annual?.limit === 'string'
  ) {
    return coverage.limits.annual.limit
  }

  if (
    typeof coverage?.limit === 'number' ||
    typeof coverage?.limit === 'string' ||
    coverage.limit === null
  ) {
    return coverage.limit
  }

  if (
    typeof coverage.limits?.['per-incident']?.limit === 'number' ||
    typeof coverage.limits?.['per-incident']?.limit === 'string' ||
    coverage.limits?.['per-incident']?.limit === null
  ) {
    return coverage.limits['per-incident'].limit
  }

  console.info(coverage)
  throw new Error('Could not determine limit')
}

export const humanReadableProductLine = (productLine: string): string =>
  startCase(productLine)

export const isVetFeesCoverage = (
  coverage: Coverage
): coverage is VetFeesCoverage =>
  coverage.coverage === 'vet_fees' || coverage.coverage === 'vet-fees'

export const getMatchingBundleFromCoverages = (
  bundles: CoverageBundle[],
  coverages: string[]
): Maybe<CoverageBundle> => {
  return bundles.find((bundle) => {
    const bundleCoverages = [...bundle.compulsory, ...bundle.optional].map(
      ({ key }) => key
    )
    return coverages.every((coverage) => bundleCoverages.includes(coverage))
  })
}
