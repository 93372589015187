import { findLast, findLastIndex } from 'lodash-es'

import type { CoverageOption } from '@/@types/Coverage'
import type {
  Adjustment,
  Connection,
  Contract,
  Lifecycle,
} from '@/@types/Lifecycle'
import type { Pet } from '@/@types/Pet'
import type { CoveragePrice } from '@/@types/Price'
import type { Customer } from '@/domain/Customer'
import dayjs from '@/lib/dayjs'
import type { DayJsParsable } from '@/lib/dayjs'
import { calculatePriceFromCoverages } from '@/lib/utils/coverageBundles'
import { formatCoverageOptions } from '@/lib/utils/coverageOptions'
import {
  getPolicyCancellationDate,
  isAdjustment,
  isPeriodEndAdjustment,
  isPolicyYearStartingAdjustment,
  isPresumedContinuingAdjustment,
  isTerminatingAdjustment,
} from '@/lib/utils/lifecycles'

import { getPolicyYearOrdinalFromPolicyData } from './policies'

type PolicyStatus =
  | 'CANCELLED'
  | 'VOID'
  | 'PRE_INCEPTION'
  | 'ON_RISK'
  | 'OUT_OF_BOUNDS' // Used when the policy is still on risk after its cessation date

export interface PolicySnapshot {
  adjustmentTimestamp: string
  cessationDate?: string
  contractEndDate?: string
  cancellationDate?: DayJsParsable
  contractStartDate: string
  coverages: Record<string, CoverageOption>
  customers: Record<string, Customer>
  firstPolicyYearStartDate: string
  inceptionDate: string
  pet: Pet
  policyUuid: string
  policyId: string
  policyDocumentId: string
  price: CoveragePrice
  product: string
  productLine: string
  ref: string
  status: PolicyStatus
  carrier?: string
  policyYearOrdinal?: string
}

const getPolicyCessationDate = (
  policyLifecycle: Lifecycle,
  effectiveAdjustment: Adjustment
): string => {
  // Cessation date is either the date of the adjustment if it is a VOID/CANCELLATION/LAPSE/PRESUMED_RENEWAL
  // or the next VOID/CANCELLATION/LAPSE/RENEWAL/PRESUMED_RENEWAL adjustment following the adjustment date
  if (
    isTerminatingAdjustment(effectiveAdjustment) ||
    isPresumedContinuingAdjustment(effectiveAdjustment)
  ) {
    return effectiveAdjustment.date
  } else {
    const cessationAdjustment = policyLifecycle.connections
      .filter(isAdjustment)
      .find(
        (adjustment: Adjustment) =>
          isPeriodEndAdjustment(adjustment) &&
          dayjs(adjustment.date).isAfter(dayjs(effectiveAdjustment.date))
      )! // there must always be a cessation adjustment

    if (cessationAdjustment) {
      const lastDayOfCover = dayjs(cessationAdjustment.date)
        .subtract(1, 'day')
        .format('YYYY-MM-DD')

      return lastDayOfCover
    }

    return ''
  }
}

const getPolicyInceptionDate = (
  policyLifecycle: Lifecycle,
  effectiveAdjustment: Adjustment
): string => {
  // Inception date is either the date of the adjustment if it is a SALE or RENEWAL
  // or the last SALE/RENEWAL adjustment preceding the adjustment date
  if (isPolicyYearStartingAdjustment(effectiveAdjustment)) {
    return effectiveAdjustment.date
  } else {
    const inceptionAdjustment = findLast(
      policyLifecycle.connections,
      (connection: Connection) =>
        isAdjustment(connection) &&
        isPolicyYearStartingAdjustment(connection) &&
        dayjs(connection.date).isSameOrBefore(dayjs(effectiveAdjustment.date))
    )

    return (inceptionAdjustment as Adjustment)?.date
  }
}

export const getCurrentPolicySnapshot = (
  lifecycles: Lifecycle[],
  policyId: string
): Maybe<PolicySnapshot> => {
  return getPolicySnapshotAtDate(lifecycles, policyId, dayjs(), false)
}

/**
 * @param {Array[Lifecycle]} lifecycles Complete set of customer lifecycles - for all policies
 * @param {string} policyId - the policy we want the snapshot for
 * @param {DayJsParsable} [date] - the date we want the snapshot for. Defaults to now.
 * @param {boolean} strict - whether to use strict mode. Defaults to true.
 * @returns {PolicySnapshot} - the snapshot of the policy at the date
 */
export function getPolicySnapshotAtDate(
  lifecycles: Lifecycle[],
  policyId: string,
  date: DayJsParsable = dayjs(),
  strict: boolean = true
): Maybe<PolicySnapshot> {
  const lifecycle = lifecycles.find(
    (lifecycle) => lifecycle.policy === policyId
  )

  if (!lifecycle) {
    throw new Error(`Unable to find lifecycle for policy ${policyId}`)
  }

  if (!lifecycle.connections?.length) {
    throw new Error(
      `Unable to find lifecycle connections for policy ${policyId}`
    )
  }

  const { connections } = lifecycle

  const firstAdjustment = connections.at(0) as Adjustment

  // Convert date to UTC time. This is necessary if an ISO string is used from another timezone, that could be a different (and earlier date) than the expected date on an adjustment.
  const formattedDate = dayjs.tz(date, 'UTC').format('YYYY-MM-DD')

  let effectiveAdjustmentIndex = findLastIndex(
    connections,
    (connection: Connection) =>
      isAdjustment(connection) &&
      dayjs(connection.date).isSameOrBefore(dayjs(formattedDate), 'day')
  )

  if (effectiveAdjustmentIndex < 0) {
    // if we get here, it means there is no adjustment that is same or before the date.
    // however if we have an adjustment in the future, we want the first adjustment index to display to the user.
    if (!strict && connections.length) {
      effectiveAdjustmentIndex = 0
    } else {
      throw new Error(
        `Unable to find a contract for policy ${policyId} and date ${formattedDate}`
      )
    }
  }

  const effectiveAdjustment: Adjustment = connections[
    effectiveAdjustmentIndex
  ] as Adjustment

  const effectiveContract: Maybe<Contract> = (
    isTerminatingAdjustment(effectiveAdjustment) ||
    isPresumedContinuingAdjustment(effectiveAdjustment)
      ? connections[effectiveAdjustmentIndex - 1]
      : connections[effectiveAdjustmentIndex + 1]
  ) as Contract

  const subsequentAdjustment: Maybe<Adjustment> = connections[
    effectiveAdjustmentIndex + 2
  ] as Maybe<Adjustment>

  const cancellationDate = getPolicyCancellationDate({ lifecycle })
  const status = getPolicyStatus(lifecycle, formattedDate)

  const result: PolicySnapshot = {
    adjustmentTimestamp: effectiveAdjustment.updated_at,
    inceptionDate: getPolicyInceptionDate(lifecycle, effectiveAdjustment),
    firstPolicyYearStartDate: firstAdjustment.date,
    policyUuid: lifecycle.policy_uuid,
    policyId,
    policyDocumentId:
      lifecycle.documents[`${effectiveAdjustment.id},${effectiveContract?.id}`],
    price: calculatePriceFromCoverages(effectiveContract.coverages),
    product: effectiveContract.product,
    contractStartDate: effectiveAdjustment.date,
    ref: effectiveContract.ref,
    productLine: lifecycle.product_line,
    coverages: formatCoverageOptions(effectiveContract.coverages),
    customers: effectiveContract.customers,
    pet: effectiveContract.pet,
    status,
    carrier: effectiveContract.carrier,
  }

  const cessationDate = getPolicyCessationDate(lifecycle, effectiveAdjustment)
  if (cessationDate) {
    result.cessationDate = cessationDate
  }

  const contractEndDate = subsequentAdjustment
    ? dayjs(subsequentAdjustment.date).subtract(1, 'day').format('YYYY-MM-DD')
    : undefined
  if (contractEndDate) {
    result.contractEndDate = contractEndDate
  }

  if (cancellationDate) {
    result.cancellationDate = cancellationDate
  }

  const endDate = cancellationDate ?? contractEndDate

  if (firstAdjustment.date_utc && endDate) {
    const policyYearOrdinal = getPolicyYearOrdinalFromPolicyData({
      originalPolicyStartDate: firstAdjustment.date_utc,
      cessationDate: endDate as string,
      status,
    })

    result.policyYearOrdinal = policyYearOrdinal
  }

  return result
}

/**
 * @param {Lifecycle} lifecycle The lifecycle
 * @param {boolean} strict Strict mode
 * @param {string} date The date
 * @returns {object} The contract
 */
function getContractForDate(
  lifecycle: Lifecycle,
  strict: boolean,
  date: string
): Maybe<Contract> {
  const { connections } = lifecycle

  if (!connections?.length) {
    return undefined
  }

  const firstAdjustment = connections.at(0) as Adjustment
  const lastAdjustment = connections.at(-1) as Adjustment

  if (strict) {
    if (date < firstAdjustment.date) {
      return undefined
    } else if (date >= lastAdjustment.date) {
      return undefined
    }
  } else {
    if (date < firstAdjustment.date) {
      return connections.at(1) as Contract
    } else if (date >= lastAdjustment.date) {
      return connections.at(-2) as Contract
    }
  }

  for (const [index, connection] of connections.entries()) {
    if (isAdjustment(connection) && connection.date > date) {
      return connections[index - 1] as Maybe<Contract>
    }
  }

  return undefined
}

/**
 * @param {Lifecycle} lifecycle The lifecycle
 * @param {string} date The date
 * @returns {string} The status
 */
function getPolicyStatus(lifecycle: Lifecycle, date: string): PolicyStatus {
  if (getContractForDate(lifecycle, true, date)) {
    return 'ON_RISK'
  }

  const firstAdjustment = lifecycle.connections.at(0) as Adjustment
  const lastAdjustment = lifecycle.connections.at(-1) as Adjustment

  if (lastAdjustment.date <= date) {
    if (
      lastAdjustment.type === 'CANCELLATION' ||
      lastAdjustment.type === 'LAPSE'
    ) {
      return 'CANCELLED'
    } else if (lastAdjustment.type === 'VOID') {
      return 'VOID'
    }
  } else if (firstAdjustment.date > date && firstAdjustment.type === 'SALE') {
    return 'PRE_INCEPTION'
  }

  // If we haven't established a status yet then check the contract again with strict mode turned off
  // This is to handle the case where the date is before the first adjustment or after the last adjustment
  if (getContractForDate(lifecycle, false, date)) {
    return 'OUT_OF_BOUNDS'
  }

  throw new Error('Unknown policy status')
}

export const getPolicySnapshotOwner = (policy: PolicySnapshot): string => {
  // a lifecycle policy will only have one customer
  return Object.keys(policy.customers)[0]
}

export const isPolicyCancellable = (policy: PolicySnapshot): boolean => {
  return policy.status !== 'CANCELLED' && policy.status !== 'VOID'
}
